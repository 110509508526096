import React from 'react';
import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './routes/login';
import { AuthProvider } from './context/authenticationContext';
import Dashboard from './routes/dashboard';

const router = createBrowserRouter([
  {
    path: '/',
    Component: LoginPage
  },
  {
    path: '/dashboard',
    Component: Dashboard
  },
  {
    path: '/dashboard/:id',
    Component: Dashboard
  }
])

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
