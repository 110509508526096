



export const networkService = {
    async get<T>(url: string) {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        const data = await response.json();
        if (response.ok)
            return data as T;
        else
            throw new Error(data.message);
    },
    async post<T>(url: string, body: any) {
        const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        const data = await response.json();
        if (response.ok) {
            return data as unknown as T;
        } else
            throw new Error(data.message);
    },
}