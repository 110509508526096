import { Box, styled, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import socketIOClient, { io } from "socket.io-client"
import socket from "../service/socket";
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { networkService } from "../service/networkService";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { LineChart } from "@mui/x-charts";
import { group } from "console";

class BroadcastPlotterService {

    public static plotToLine(data: broadcast[]) {
        const grouped = BroadcastPlotterService.groupByMinute(data, 1);
        return {
            x: Object.keys(grouped),
            y: Object.values(grouped)
        }
    }

    public static groupByMinute = (broadcasts: broadcast[], minutes: number) => {
        return BroadcastPlotterService.groupBy(broadcasts, (date: Date) => {
            const d = new Date(date);
            d.setSeconds(0);
            d.setMilliseconds(0);
            d.setMinutes(Math.floor(d.getMinutes() / minutes) * minutes);
            return d;
        });
    }

    public static groupByHour = (broadcasts: broadcast[]) => {
        return BroadcastPlotterService.groupBy(broadcasts, (date: Date) => {
            const d = new Date(date);
            d.setSeconds(0);
            d.setMilliseconds(0);
            d.setMinutes(0);
            return d;
        });
    }

    public static groupByDay = (broadcasts: broadcast[]) => {
        return BroadcastPlotterService.groupBy(broadcasts, (date: Date) => {
            const d = new Date(date);
            d.setSeconds(0);
            d.setMilliseconds(0);
            d.setMinutes(0);
            d.setHours(0);
            return d;
        });
    }

    public static groupBy = (broadcasts: broadcast[], rounder: (argO: Date) => Date) => {
        const grouped: { [key: string]: broadcast[] } = {};

        broadcasts.forEach(broadcast => {
            const date = new Date(broadcast.request_date_time);
            const roundedDate = rounder(date);
            const key = roundedDate.toISOString();

            if (!grouped[key]) {
                grouped[key] = [];
            }
            grouped[key].push(broadcast);
        });
        return grouped;
    };
}

enum EDeviceType {
    sun = 0,
    watch = 1
}

type device = {
    id: string;
    device_id: string;
    created_at: string;
    device_type: string;
}

const useDevices = () => {
    const [errors, setErrors] = useState<string[]>();
    const [devices, setDevices] = useState<device[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<device>();

    useEffect(() => {
        setLoading(true);
        networkService.get<{ devices: device[] }>("/api/devices")
            .then((data) => {
                setDevices(data.devices);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    const fetchDevice = (selectedDevice: string) => {
        networkService.get<{ device: device }>("/api/device/" + selectedDevice)
            .then((data) => {
                setSelectedDevice(data.device);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        devices,
        loading,
        fetchDevice,
        selectedDevice
    }
}

enum SocketState {
    connected,
    disconnected,
    error,
}

enum ERequestType {
    CALL = 0,
    BILL = 1,
    RESPONSE = 2,
}

type broadcast = {
    request_source: string;
    request_date_time: string;
    request_type: ERequestType;
}

const UseBroadcastChannel = (deviceId: string) => {
    const [state, setState] = useState<SocketState>(socket.connected ? SocketState.connected : SocketState.disconnected);
    const [broadcasts, setBroadcasts] = useState<broadcast[]>([]);

    const onConnect = () => setState(SocketState.connected);
    const onDisconnect = () => setState(SocketState.disconnected);
    const onBroadcastEvent = (data: broadcast) => {
        setBroadcasts((prev) => [...prev, data]);
    }

    const onError = (errors: any) => {
        console.error(errors);
        setState(SocketState.error);
    };



    useEffect(() => {
        if (socket.connected)
            socket.disconnect();

        socket.connect();
        networkService.get<{ broadcasts: broadcast[] }>(`/api/requests?id=${deviceId}`)
            .then((data) => {
                console.log(data?.broadcasts)
                setBroadcasts(data.broadcasts);
            })
            .catch((error) => {
                console.error(error);
            })
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('error', onError);
        socket.on(deviceId, onBroadcastEvent);

        return () => {
            socket.disconnect();

            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('error', onError);
            socket.off(deviceId, onBroadcastEvent);
        };
    }, [deviceId]);

    return {
        state,
        broadcasts,
    }
}


const DeviceInformation = ({ device, count }: { device: device; count: number }) => {
    return (
        <Box gap={8} padding={0.625}>
            <Typography variant="h4" align="left">General information</Typography>
            <TableContainer  >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography align="left">Device ID:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="left">{device.device_id}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography align="left">Created At: </Typography>
                            </TableCell>
                            <TableCell>
                                {new Date(device.created_at).toLocaleString()}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography align="left">Device Type:</Typography>
                            </TableCell>
                            <TableCell>
                                <span style={{ fontWeight: "bold" }}>{parseInt(device.device_type) === EDeviceType.sun ? "Sun" : "Watch"}</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography align="left">Number of broadcast:</Typography>
                            </TableCell>
                            <TableCell>
                                <span style={{ fontWeight: "bold" }}>{count}</span>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>

        </Box>
    )

}

const DeviceDashboard = ({ selectedDevice }: { selectedDevice: device }) => {
    const { state, broadcasts } = UseBroadcastChannel(selectedDevice.device_id);

    return (
        <Box height={"100%"} display={"flex"}>
            <Box display={"flex"} flex={1}>
                {
                    selectedDevice ?
                        <Box display={'flex'} flexDirection={"column"}>
                            <DeviceInformation device={selectedDevice} count={broadcasts.length} />
                            <Box flex={1} flexDirection={"row"} display={"flex"}>

                                <LineChart
                                    xAxis={[{
                                        data: BroadcastPlotterService.plotToLine(broadcasts).x.map(x => new Date(x)),
                                        scaleType: "point"
                                    }]}
                                    series={[
                                        {
                                            data: BroadcastPlotterService.plotToLine(broadcasts).y.map(value => value.length)
                                        },
                                    ]}
                                    width={500}
                                    height={300}
                                />
                            </Box>
                        </Box>
                        :
                        <>
                            <Typography>Select a device in the list.</Typography>
                        </>
                }
            </Box>
        </Box>

    );
}

const Dashboard = () => {
    const { devices, loading, fetchDevice, selectedDevice } = useDevices();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id)
            fetchDevice(id);
    }, [id])

    return (
        <Box width={"100vw"} height={"100vh"}>
            <Box display={"flex"} flexDirection={"row"} flex={1} height={'100%'}>
                <Box flex={1}>
                    <DataGrid
                        rowSelection={true}
                        rows={devices}
                        loading={loading}
                        onRowClick={(row) => {
                            console.log(row.id);
                            navigate(`/dashboard/${row.id}`);
                        }}
                        columns={[
                            {
                                field: 'device_id',
                            },
                            {
                                field: 'created_at',
                            },
                            {
                                field: 'device_type',
                                valueGetter: (value) => {
                                    return value === EDeviceType.sun ? "Sun" : "Watch";
                                }
                            }
                        ]}
                    />
                </Box>
                <Box flex={4}>
                    {
                        selectedDevice ?
                            <>
                                <DeviceDashboard selectedDevice={selectedDevice} />
                            </>
                            :
                            <p>Select a device in the list.</p>
                    }
                </Box>
            </Box>
        </Box>

    );
}


export default Dashboard;