import socketIOClient, { io } from "socket.io-client"


const socket = io("/", {
    transports: ["websocket"],
    protocols: ["echo-protocol"]
});


export default socket;

